import React, {useState } from 'react';
import {
  Container, 
  Row,
  Col,
  Form,
  Button,
  Alert
} 
from 'react-bootstrap';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios  from 'axios';



const style = {
  form: {
    minHeigth: '500px',
    padding: '20px',
    margin: 'auto',
    marginTop: '30px',
    border: '0'
  },
  submit: {
    marginTop: '10px',
    float: 'left'
  }
}

function App() {

    const [inputs, setInputs] = useState({
        email: "",
        message: "",
        name: "",
      });
      const handleOnChange = event => {
        event.persist();
        setInputs(prev => ({
          ...prev,
          [event.target.id]: event.target.value
        }));
      };
      /* End input state handling ^^^^ */
    
      // Server state handling
      const [serverState, setServerState] = useState({
        submitting: false,
        status: null
      });
      const handleServerResponse = (ok, msg) => {
        setServerState({
          submitting: false,
          status: { ok, msg }
        });
        if (ok) {
          setInputs({
            email: "",
            message: "",
            name: "",
          });
        }
      };


      const handleOnSubmit = event => {
        event.preventDefault();
        setServerState({ submitting: true });
        axios({
          method: "POST",
          url: "https://formspree.io/xeqlgrea",
          data: inputs
        })
          .then(r => {
            handleServerResponse(true, <Alert style={{marginTop: '59px'}}variant="success">Thanks! We will be in touch soon.</Alert>
            );
          })
          .catch(r => {
            handleServerResponse(false, <Alert style={{marginTop: '59px'}}variant="warning">{r.response.data.error}</Alert>);
          });
      };


  return (
    <div className="App">
        <Container>  
        <Row style={style.form} className='text-left shrink-lg-up-55' id="newsletter">
          <Col>
          <h4 className="text-left" >Contact Us</h4><br/>

          <Form>
            <Form.Group controlId="name" className='left-align'>
                <Form.Label>Name</Form.Label>
                <Form.Control type="email" placeholder="" onChange={handleOnChange} value={inputs.name}/>
            </Form.Group>
            <Form.Group controlId="email" className='left-align'>
                <Form.Label>Email address</Form.Label>
                <Form.Control type="text" required placeholder="" onChange={handleOnChange} value={inputs.email} />
            </Form.Group>

            <Form.Group controlId="message" className='left-align'>
                <Form.Label>How can we help?</Form.Label>
                <Form.Control as="textarea" rows="5" onChange={handleOnChange} value={inputs.message}/>
            </Form.Group>
            <Button style={style.submit} onClick={handleOnSubmit} variant="primary" type="submit">
                Submit
            </Button>
            </Form>
            {serverState.status && (
                    <p className={!serverState.status.ok ? "errorMsg" : ""}>
                        {serverState.status.msg}
                    </p>
                    
                    )}
          </Col>
        </Row>
        </Container>  
    </div>
  );
}

export default App;
