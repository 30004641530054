import React from 'react';
import {
  Row,
  Col,
  Form,
  Card,
  Button,
  Container
} 
from 'react-bootstrap';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MailchimpSubscribe from "react-mailchimp-subscribe"


const style = {
  navbar: {
    minHeight: '80px'
  },
  topBanner: {
    padding: '60px'
  },
  paragraph: {
    padding: '60px 60px 0 60px'
  },
  newsletter: {
    backgroundColor: '#FFF7D8',
    padding: '10px',
    margin: 'auto',
    border: '0'
  },
  submit: {
    marginTop: '10px',
    float: 'left'
  }
}

const url = "https://vc-finance.us19.list-manage.com/subscribe/post?u=469e0098c07f6e01a85073c23&amp;id=1c46d55229";




function App() {

    let emailRef

  return (

    <Container>

        <Row style={style.paragraph} id="newsletter">
          <Col>
          <Card style={style.newsletter} className="shrink-lg-up-55">
              <Card.Body>
              <MailchimpSubscribe
                    url={url}
                    render={({ subscribe, status, message }) => (
                        <div>
                            <Form onSubmit={(e) => {
                                e.preventDefault()
                                subscribe({EMAIL: emailRef.value})
                            
                            }}>
                                <h3 className="text-left font-italic">Newsletter</h3>
                                <h5 className="text-left text-muted ">Sign Up for Industry News and Updates</h5>
                                <Form.Group controlId="exampleForm.ControlInput1">
                                    <Form.Control style={{backgroundColor: '#FFF7D8', borderColor: 'black', margin: '13px 0px'}} ref={node => (emailRef = node)} type="email" placeholder="Email" />
                                    <Button style={style.submit} variant="dark" type="submit">
                                    Submit
                                    </Button>
                                </Form.Group>
                            </Form>
                        {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
                        {status === "error" && <div style={{ color: "red" }} dangerouslySetInnerHTML={{__html: message}}/>}
                        {status === "success" && <div style={{ color: "green" }}>Subscribed !</div>}
                        </div>
                    )}
                />
              </Card.Body>
            </Card>      
          </Col>
        </Row>
     </Container>




  );
}

export default App;
