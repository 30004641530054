import React from 'react';
import {
  Container, 
} 
from 'react-bootstrap';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const style = {
  container: {
    minHeight: '500px',
    paddingTop: '50px'
  }
}

function App() {
  return (
    <div className="App" id="top">
        <Container style={style.container} className='left-align'>
        <h3 className="text-left">Imprint</h3><br/>
        <p className="text-left">René Kampschulte<br/>
        c/o WeWork<br/>
        135 Madison Ave<br/>
         New York, NY 10016<br/>
        United States</p>
        </Container>  
    </div>
  );
}

export default App;
