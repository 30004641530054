import React from 'react';
import { 
  Container,
  Row,
  Col,
} 
from 'react-bootstrap';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faEdit } from '@fortawesome/free-solid-svg-icons'
import {Link as RouterLink} from "react-router-dom";
import {HashLink} from 'react-router-hash-link';



const style = {
  footer: {
    minHeight: '200px',
    marginTop: '200px',
  },
  footerContent: {
    padding: '20px',      
  },
  link: {
      color: '#f8f9fa'
  },
  icon: {
      marginRight: '8px'
  }
}

function App() {
  return (
    <div className="Footer">
        <Container className="bg-dark text-light" style={style.footer}>
            <hr/>
            <Row style={style.footerContent} >
            <Col>
                <h5 className="text-left">Contact</h5><br/>
                <p className="text-left">René Kampschulte</p>
                <p className="text-left"><FontAwesomeIcon style={style.icon} icon={faEnvelope} /> <a style={style.link} href="mailto:contact@vc-finance.com">contact@vc-finance.com</a></p>
                <p className="text-left"><FontAwesomeIcon style={style.icon} icon={faEdit} /><RouterLink style={style.link} to="/contact">Contact Form</RouterLink></p><br/>

            </Col>
            </Row>
            <Row>
                <Col>
                <p className="text-center">
                  <HashLink style={style.link} to="/imprint#top">Imprint</HashLink> | Copyright © René Kampschulte {(new Date().getFullYear())}</p>
                </Col>
            </Row>
        </Container>
    </div>
  );
}

export default App;
