import React from 'react';
import {
  Container, 
  Row,
  Col,
  Image,
} 
from 'react-bootstrap';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Newsletter from './newsletter';
import {Link as RouterLink} from "react-router-dom";


const style = {
  navbar: {
    minHeight: '80px'
  },
  topBanner: {
    padding: '120px 60px'
  },
  paragraph: {
    padding: '60px 60px 0 60px'
  },
  list: {
    padding: '10px 60px',
    textAlign: 'left'
  },
  textUnderline: {
    fontWeight: '600',
    textDecoration: 'underline',
    textDecorationColor: '#E18728'
  },
  quote: {
    paddingLeft: '10px',
    lineHeight: '48px',
  },
  banner: {
    backgroundColor: '#909db042'
  },
  buttonRow: {
    padding: '20px 60px 0 60px'
  },
  button: {
  },
  submit: {
    marginTop: '10px',
    float: 'left'
  },
  img: {
    width: '100%'
  }
}

function App() {
  return (
    <div className="App">
        
        <Container>
        <Row style={style.topBanner} className="d-flex align-items-center">
          <Col xs={{span: 12, order: 2}} md={{span: 6, order: 1}}>
            <Image className="shrink-md-down-max80" style={style.img} src="img/desk.jpg" fluid />
          </Col>
          <Col xs={{span: 12, order: 1}} md={{span: 6, order: 2}}>
            <h3 style={style.quote} className="text-left font-italic shrink-md-down-h3">
            We provide <span style={style.textUnderline}>specialist advice</span> on how to run <span style={style.textUnderline}>finance operations in alternative investment structures</span>.  Best practices take time to develop, so clients benefit from our experience.
            <br/></h3><h4 className="d-none d-lg-block" style={{fontStyle: 'normal', marginTop: '15px', fontWeight: '400'}}>René Kampschulte</h4>
            
          </Col>
        </Row>
        </Container>

        <div style={style.banner}>
          <Container>
          <Row style={style.topBanner}>
            <Col>
              <h1>Helping aspiring investment teams build first class finance operations</h1>
            </Col>
          </Row>
          </Container>
        </div>

        <Container>
        <Row style={style.paragraph}>
          <Col>
            <h4 className="text-left" id="about"> About Us</h4>
            <p className="text-left left-align">René Kampschulte has 12+ years of experience leading finance operations at investment funds. He started his career at a listed Private Equity Fund before joining one of the largest
            Venture Capital teams in Europe. He built finance operations from the ground up and scaled them to support multiple fund generations and global investment activities. He has a strong interest in technology and is always looking for innovative ways
            to optimize finance departments by using outsourced experts and IT.</p>
          </Col>
        </Row>
        </Container>

        
        <Newsletter />
       
        <Container>
        <Row style={style.paragraph}>
          <Col>
            <h4 className="text-left" id="functions">Functions</h4>
            <p className="text-left">We support all investment, reporting and compliance processes:</p>
          </Col>
        </Row>
        <Row style={style.list}>
          <Col>
            <ul className="list">
              <li>Capital Calls</li>
              <li>Quarterly Reporting</li>
              <li>IFRS and GAAP Accounts</li>
              <li>Tax Compliance</li>
              <li>Fund Closing</li>
              <li>Portfolio Reporting</li>
              <li>Valuations</li>
            </ul>
          </Col>
          <Col>
            <ul className="list">
              <li>Waterfall and Carry Calculations</li>
              <li>PFIC Confirmations</li>
              <li>Compliance Handbooks</li>
              <li>Investor Due Diligence</li>
              <li>CRM Systems and Investor Portals</li>
              <li>Management Reporting</li>
              <li>Fund Financing</li>
            </ul>
          </Col>
          <Col>
                
          </Col>
        </Row>

        <Row style={style.buttonRow}>
          <Col>
            <RouterLink className='btn btn-lg btn-outline-dark' to="/contact">Get in Touch</RouterLink>
          </Col>
        </Row>
        </Container>  
    </div>
  );
}

export default App;
