import React from 'react';
import { 
  Container,
  Navbar,
  Nav,
} 
from 'react-bootstrap';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Home from './home';
import Approach from './approach';
import Footer from './footer';
import Imprint from './imprint';
import Contact from './contact';
import {Link as RouterLink} from "react-router-dom";
import {HashLink} from 'react-router-hash-link';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-158336478-2');
ReactGA.pageview(window.location.pathname + window.location.search);




const style = {
  navbar: {
    minHeight: '80px',
    fontWeight: '600',
  },
  navlink:{
    marginLeft: '30px',
    hover: {
      textDecoration: 'underline'
    },
  },
  topBanner: {
    padding: '60px'
  },
  paragraph: {
    padding: '60px 60px 0 60px'
  },
  list: {
    padding: '10px 60px',
    textAlign: 'left'
  },
  quote: {
    paddingLeft: '10px',
    lineHeight: '48px',
  },
  newsletter: {
    backgroundColor: '#FFF7D8',
    width: '55%',
    padding: '20px',
    margin: 'auto',
    border: '0'
  },
  submit: {
    marginTop: '10px',
    float: 'left'
  }
}


function App() {
  return (
    <Router>
    <div className="App">
        <Navbar style={style.navbar} collapseOnSelect expand="lg">
          <Container>
          <RouterLink className="navbar-brand" to="/home">VC Finance</RouterLink>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <RouterLink style={style.navlink} className="nav-link" to="/home">About Us</RouterLink>
              <RouterLink style={style.navlink} className="nav-link" to="/approach">Approach</RouterLink>
            </Nav>
            <Nav>
              <RouterLink style={style.navlink} className="nav-link" to="/contact">Contact</RouterLink>
              <HashLink style={style.navlink} className="nav-link" to="/home#newsletter">Newsletter</HashLink>
            </Nav>
          </Navbar.Collapse>
          </Container>
        </Navbar>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/imprint"  element={<Imprint />} />
          <Route path="/approach" element={<Approach />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      <Footer />

        
    </div>
    </Router>
  );
}

export default App;
