import React from 'react';
import { 
  Container,
  Row,
  Col,
  Image,
} 
from 'react-bootstrap';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const style = {
  navbar: {
    minHeight: '80px'
  },
  topBanner: {
    padding: '60px'
  },
  banner: {
    backgroundColor: '#909db042'
  },
  paragraph: {
    padding: '60px 60px 0 60px'
  },
  list: {
    padding: '10px 60px',
    textAlign: 'left'
  },
  quote: {
    paddingLeft: '10px',
    lineHeight: '48px',
  },
  schema: {
    border: '1px'
  },
  submit: {
    marginTop: '10px',
    float: 'left'
  }
}

function App() {
  return (
    <div className="Approach">

      <div style={style.banner}>
      <Container>
        <Row style={style.topBanner}>
          <Col>
            <h1>With CFO as a Service teams  build professional finance functions while staying lean</h1>
          </Col>
        </Row>
      </Container>
      </div>

      <Container>
        <Row style={style.paragraph}>
          <Col>
            <h4 className="text-left" id="approach">Our Approach</h4>
            <p className="text-left left-align">The finance function forms an integral part of most professional investment setups. After all, numbers need to add up and operations are expected to run smoothly.<br/><br/>
            For this, teams need to combine senior guidance with a strong operations team. Our approach is to support teams in setting up processes and optimizing operations.
            We monitor progress and take action when requirements change or questions arise.<br/><br/>
            A senior full-time CFO role is not a fully utilised role in smaller teams, and therefore is not necessarily needed. Sharing this task is the concept behind our approach. We want to
            provide access to best practices while maintaining an efficient team setup. 
            </p>
          </Col>
        </Row>
        <Row style={style.paragraph} id="network">
          <Col>
            <Image style={style.schema} className="shrink-lg-up-45" src="img/schema_export.svg" fluid />
          </Col>
        </Row>

        <Row style={style.paragraph} id="network">
          <Col>
            <h4 className="text-left" >Network & Outsourcing</h4>
            <p className="text-left left-align">We are connected to a network of professional service providers that focus on the services needed to run finance operations. Using 
            experts in this domain can save time and money. <br/><br/>Building on our expertise, we help identify the best provider for a particular service and help set up an efficient collaboration.</p>
          </Col>
        </Row>
        
      </Container>
    </div>
  );
}

export default App;
